export const environment = {
  production: false,
  name: 'QA',
  api: 'https://credits-api-qa.unicomer.com/credits-smart-credit-backend-qa',
  keycloak: {
    realm: "credits",
    url: "https://oauth-devqa-yfevihrw.unicomer.com/auth/",
    ssl_required: "external",
    clientId: "frontend-credit",
    confidential_port: "0"
  },
  strapi_url: 'https://cms-devqa-ohiqfxwv.unicomer.com/cms/api',
  kibana: {
    serverUrl: 'https://79dcb527fa014de297bd2ee41306dfe2.apm.us-east1.gcp.elastic-cloud.com:443',
    serviceName: 'smart-credit-frontend',
    environment: 'qa',
  },
};
